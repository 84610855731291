@media (max-width: 768px) {
  .header-content {
    margin-top: 25px;
    text-align: center;
  }
  footer .text-left {
    text-align: center !important;
  }
  footer .text-right {
    text-align: center !important;
  }
  footer .container {
    line-height: 30px;
    padding-top: 10px;
  }
}
